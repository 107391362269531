import React, { FunctionComponent } from "react";
import styled from "styled-components";
import * as colors from "../../constants/Colors";

const Contact: FunctionComponent = () => {
  return (
    <Container>
      <List>
        <ListItem>
          <Link href="https://m.me/sindrikaldal">
            <i className="fab fa-facebook-messenger fa-3x"></i>
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://github.com/sindrikaldal">
            <i className="fab fa-github fa-3x"></i>
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://linkedin.com/in/sindrikaldal">
            <i className="fab fa-linkedin-in fa-3x"></i>
          </Link>
        </ListItem>
        <ListItem>
          <Link href="mailto:sindrimars@gmail.com">
            <i className="fas fa-at fa-3x"></i>
          </Link>
        </ListItem>
      </List>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${colors.mainColor};
`;

const List = styled.ul`
  list-style-type: none;
  width: 70%;
  margin: auto;
  text-align: center;
`;

const Link = styled.a`
  color: ${colors.backgroundColor};
`;

const ListItem = styled.li`
  display: inline-block;
  padding: 30px;
`;

export default Contact;
