import React, { FunctionComponent, useState } from "react";
import { Transition } from "react-transition-group";
import styled from "styled-components";
import * as colors from "../../constants/Colors";
import * as fonts from "../../constants/fonts";

interface Props {
  company: string;
  description: string;
  techStack: string[];
  startDate: string;
  endDate: string;
}

export const CvEntry: FunctionComponent<Props> = (props: Props) => {
  const { company, description, techStack, startDate, endDate } = props;

  const [showDescription, setShowDescription] = useState(false);

  const expand = () => {
    setShowDescription(!showDescription);
  };

  return (
    <Root>
      <ExpandContainer>
        <GridItem
          showDescription={showDescription}
          alignment="left"
          hideOnMobile={true}
        >
          {startDate} - {endDate}
        </GridItem>
        <GridItem
          showDescription={showDescription}
          alignment="center"
          hideOnMobile={false}
        >
          {company}
        </GridItem>
        <ExpandButton descriptionShown={showDescription} onClick={expand}>
          <Transition in={showDescription} timeout={0}>
            {(state: string) => (
              <PlusSign state={state} className="fas fa-plus fa-2x"></PlusSign>
            )}
          </Transition>
        </ExpandButton>
      </ExpandContainer>
      <Transition in={showDescription} timeout={0}>
        {(state: string) => (
          <DescriptionContainer state={state}>
            <Description state={state} color={colors.backgroundColor}>
              {description}
            </Description>
            <Description state={state} color="black">
              {techStack.join(" - ")}
            </Description>
          </DescriptionContainer>
        )}
      </Transition>
    </Root>
  );
};
// font-family: "Luckiest Guy", cursive;
const Root = styled.div`
  margin: 0 10rem 5rem 10rem;
  @media screen and (max-width: 700px) {
    margin: 0 0 5rem 0;
  }
`;

const ExpandContainer = styled.div`
  display: grid;
  grid-template-columns: 30% auto 6rem;
  @media screen and (max-width: 700px) {
    grid-template-columns: auto 6rem;
  }
`;

const DescriptionContainer = styled.div<{ state: string }>`
  width: 100%;
  background-color: ${colors.mainColor};
  padding: 2rem;
  transition: 1s;
  opacity: ${({ state }) => {
    switch (state) {
      case "entering":
        return "0";
      case "entered":
        return "1";
    }
  }};
  display: ${({ state }) => {
    switch (state) {
      case "exited":
        return "none";
    }
  }};
`;

const ExpandButton = styled.button<{ descriptionShown: boolean }>`
  height: 5rem;
  width: 6rem;
  cursor: pointer;
  ${props =>
    props.descriptionShown ? "border: none" : "border: 0.25rem solid #ff5e6c"};
  background-color: ${props =>
    props.descriptionShown ? colors.backgroundColor : colors.mainColor};
  &:focus {
    background-color: ${props =>
      props.descriptionShown ? colors.backgroundColor : colors.mainColor};
  }
  @media screen and (min-width: 700px) {
    transition: background-color 0.5s;
    &:hover {
      transition: background-color 0.5s;
      background-color: ${colors.backgroundColor};
      border: none;
    }
  }
`;

const PlusSign = styled.i<{ state: string }>`
  transition: 0.5s;
  /* change color*/
  transform: ${({ state }) => {
    switch (state) {
      case "entered":
        return "rotate(45deg)";
    }
  }};
  @media screen and (min-width: 700px) {
    ${ExpandButton}:hover & {
      color: ${colors.mainColor};
    }
  }
  color: ${({ state }) => {
    switch (state) {
      case "entered":
        return colors.mainColor;
    }
  }};
`;

const GridItem = styled.div<{
  alignment: string;
  hideOnMobile: boolean;
  showDescription: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.alignment};
  font-family: ${fonts.mainFont};
  font-size: 2rem;
  font-weight: bold;
  max-height: 5rem;

  border-right: 0.25rem solid ${colors.mainColor};

  @media screen and (max-width: 700px) {
    ${({ hideOnMobile }) => {
      return hideOnMobile ? "display: none;" : "";
    }};
    border-left: ${props =>
      !props.showDescription ? "0.25rem solid #ff5e6c" : "none"};
    border-right: none;
    padding: 0 1rem;
  }
`;

const Description = styled.span<{ state: string; color: string }>`
  width: 70%;
  display: block;
  margin: 0 auto;
  font-size: 2em;
  font-family: "Nunito", sans-serif;
  color: ${props => props.color};
  transition: 0.5s;
  transform: translateY(
    ${props => {
      switch (props.state) {
        case "entering":
          return "50px";
        case "entered":
          return "0px";
        case "exiting":
          return "0px";
        case "exited":
          return "50px";
      }
    }}
  );
  @media screen and (max-width: 700px) {
    width: 80%;
    font-size: 1.25em;
    font-family: "Nunito", sans-serif;
  }
`;
