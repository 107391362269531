import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface Props {
  height: number;
}

export const Spacer: FunctionComponent<Props> = (props: Props) => {
  return <Root height={props.height} />;
};
// font-family: "Luckiest Guy", cursive;
const Root = styled.div<{ height: number }>`
  height: ${props => props.height}rem;
`;
