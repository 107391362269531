import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Container } from "../shared/Container";
import { Title } from "../shared/Title";
import { Text } from "../shared/Text";

const Projects: FunctionComponent = () => {
  return (
    <Container id="projects">
      <Title text="Projects" />
      <Text text="Coming Soon..." />
    </Container>
  );
};

export default Projects;
