import React, { FunctionComponent } from "react";
import { Title } from "../shared/Title";
import { Container } from "../shared/Container";
import { CvEntry } from "./CVEntry";
import * as strings from "../../constants/Strings";
import { Spacer } from "../shared/Spacer";

const CV: FunctionComponent = () => {
  return (
    <Container id="cv">
      <Title text="Work experience" />
      <Spacer height={5} />
      <CvEntry
        company="TM"
        techStack={["React", "React Native", "Typescript"]}
        description={strings.TM_DESCRIPTION}
        startDate="2019"
        endDate="Present"
      />
      <CvEntry
        company="WOW Air"
        techStack={["C#", "Scala"]}
        description={strings.WOW_AIR_DESCRIPTION}
        startDate="2018"
        endDate="2019"
      />
      <CvEntry
        company="Síminn"
        techStack={["Angular", "Node"]}
        description={strings.SIMINN_DESCRIPTION}
        startDate="2015"
        endDate="2017"
      />
      <CvEntry
        company="Teaching assistant"
        techStack={["Java", "Python", "Erlang"]}
        description={strings.ASSISTANT_TEACHER_DESCRIPTION}
        startDate="2014"
        endDate="2017"
      />
    </Container>
  );
};

export default CV;
