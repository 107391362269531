import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface Props {
  text: string;
}

export const Title: FunctionComponent<Props> = (props: Props) => {
  return <Root>{props.text}</Root>;
};
// font-family: "Luckiest Guy", cursive;
const Root = styled.h3`
  font-family: "Pattaya", sans-serif;
  font-size: 5em;
  margin: 2rem auto 2rem;
  @media screen and (max-width: 500px) {
    font-size: 3em;
    width: 80%;
    text-align: center;
  }
`;
