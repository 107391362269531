import React, { FunctionComponent } from "react";
import styled from "styled-components";
import * as colors from "../../constants/Colors";

interface Props {
  id: "home" | "cv" | "projects";
  children: any;
}

export const Container: FunctionComponent<Props> = (props: Props) => {
  const { id, children } = props;
  return <Root id={id}>{children}</Root>;
};

const Root = styled.div`
  background-color: ${colors.backgroundColor};
  min-height: 100vh;
  border-top: 2rem solid ${colors.mainColor};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 3rem 5rem 0 5rem;
  @media screen and (max-width: 700px) {
    padding: 2rem;
  }
`;
