import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface Props {
  text: string;
}

export const Text: FunctionComponent<Props> = (props: Props) => {
  return <Root>{props.text}</Root>;
};
// font-family: "Luckiest Guy", cursive;
const Root = styled.span`
  width: 70%;
  display: block;
  margin: 0 auto;
  font-size: 2em;
  font-family: "Nunito", sans-serif;
  @media screen and (max-width: 500px) {
    width: 80%;
    font-size: 1.5em;
    font-family: "Nunito", sans-serif;
  }
`;
