import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Container } from "../shared/Container";
import { Title } from "../shared/Title";
import { Text } from "../shared/Text";

const avatar = require("../../images/avatar.png");
const avatarBlink = require("../../images/avatar-blink.png");

const Home: FunctionComponent = () => {
  let portraitRef: any;
  const onImageHover = () => {
    portraitRef.src = avatarBlink;
  };

  const onImageHoverOff = () => {
    portraitRef.src = avatar;
  };

  return (
    <Container id="home">
      <Title text="Sindri Már Kaldal" />
      <Portrait
        id="portrait"
        onMouseOver={() => onImageHover()}
        onMouseOut={() => onImageHoverOff()}
        className="circle"
        src={avatar}
        ref={image => (portraitRef = image)}
      />
      <Text text="I'm a passionate software developer & coffee drinker." />
      <br />
      <Text text="Trying to make useable software, one line at a time" />
    </Container>
  );
};

const Portrait = styled.img`
  width: 30%;
  vertical-align: middle;
  margin-bottom: 3%;
  @media screen and (max-width: 700px) {
    width: 100%;
    margin-bottom: 10%;
  }
  @media screen and (min-width: 700px) {
    transform-origin: center center;
    transition: transform 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:hover {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
    }
  }
`;

export default Home;
